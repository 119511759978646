import { USER_JWT_TOKEN_COOKIE_NAME } from "./constants";

// This middleware will check if the user has a JWT token, and redirects to the main page if he is authenticated
export async function onlyUnauthenticatedMiddleware(context) {
  const { route, app, redirect, localePath } = context;
  const hasToken =
    app.$cookie.get(USER_JWT_TOKEN_COOKIE_NAME) || route.query.admin_token;

  // If the user is authenticated
  if (hasToken) {
    await redirect(
      localePath({
        name: "indexPage",
      })
    );
  }
}
