<template>
  <component
    :is="actualComponent"
    class="bg-white border-solid border-gray-300 hover:border-primary-lighter focus:border-primary-lighter border rounded-md flex items-center justify-center h-9 w-9 md:h-11 md:w-11"
    :class="{
      'bg-[#50c0ff1a] cursor-auto pointer-events-none border-[#50C0FF]': active,
      'hover:border-gray-300 cursor-auto pointer-events-none !text-gray-300':
        disabled,
    }"
    :to="to"
    @click="linkClicked"
  >
    <slot />
  </component>
</template>

<script>
export default {
  props: {
    to: {
      type: [Object, String],
      default: null,
    },
    active: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const linkClicked = (e) => {
      if (props.disabled) {
        e.stopPropagation();
        e.preventDefault();
        return;
      }
      emit("click");
    };

    const actualComponent = props.to ? "NuxtLink" : "button";

    return {
      linkClicked,
      actualComponent,
    };
  },
};
</script>
