<template>
  <JoszakiAutocomplete
    ref="personAutocomplete"
    v-model="personValue"
    v-bind="$attrs"
    icon="user"
    true-autocomplete-mode
    :items="filteredPeople"
    :formatter="formatPerson"
    :loading="loading"
    @input="onInput"
    @focus="onFocus"
    @blur="onBlur"
    @select="onSelect"
  >
    <template #empty>
      {{ $t("conductor.common.noResults") }}
    </template>
  </JoszakiAutocomplete>
</template>

<script>
import { ref } from "@nuxtjs/composition-api";
import { debounce } from "~/helpers/debounce";
import { usePersonActions } from "~/components/_refactored/conductor/persons/usePersonActions";

export default {
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const personActions = usePersonActions();
    const formatPerson = (person) => {
      if (!person) {
        return "-";
      }
      return `${person.name} | ${person.email} | #${person.id} | ${
        person.phoneNumber?.number ?? ""
      }`;
    };

    const filteredPeople = ref([]);
    const personFilter = ref("");
    const personValue = ref(props.value ? formatPerson(props.value) : "");
    const focused = ref(false);
    const loading = ref(false);

    const onInput = debounce(async function (search) {
      try {
        loading.value = true;
        const resp = await personActions.getPersons({
          page: 1,
          pageSize: 20,
          search,
          orderBy: "name",
          order: "asc",
        });
        filteredPeople.value = resp.items;
      } finally {
        loading.value = false;
      }
    }, 500);

    return {
      filteredPeople,
      personFilter,
      personValue,
      onInput,
      focused,
      formatPerson,
      loading,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.personValue = this.formatPerson(newValue);
        }
      },
    },
  },
  methods: {
    onFocus() {
      this.focused = true;
      this.personValue = this.personFilter;
      this.$emit("focus");
    },
    onBlur() {
      this.focused = false;
      this.personFilter = this.personValue;
      this.personValue = this.formatPerson(this.value);
      this.$emit("blur");
    },
    onSelect(person) {
      this.personFilter = "";
      if (person) {
        this.$emit("select", person);
      } else {
        this.onBlur();
      }
    },
  },
};
</script>
