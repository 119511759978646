import { ref, watch, useContext } from "@nuxtjs/composition-api";
import { debounce } from "~/helpers/debounce";
import CITIES from "@/graphql/cities.graphql";

export function useCitySearch(filterCapital = false) {
  const debouncedCityFilter = ref("");
  const cityFilter = ref("");
  const loading = ref(false);
  const cities = ref([]);
  const userLocation = ref(null);

  const { $query, $ab, $trackers } = useContext();

  async function fetchCities() {
    loading.value = true;
    try {
      const resp = await $query(
        CITIES,
        {
          input: {
            nearCoords: userLocation.value,
            keyword: debouncedCityFilter.value,
            filterCapital,
          },
        },
        {
          showErrorToast: true,
        }
      );
      cities.value = resp.cities;
    } catch (error) {
    } finally {
      loading.value = false;
    }
  }

  watch([debouncedCityFilter, userLocation], fetchCities, { immediate: true });

  // Load results
  watch(
    cityFilter,
    debounce((newValue, oldValue) => {
      if (newValue === oldValue) {
        return;
      }
      debouncedCityFilter.value = newValue;
    }, 300),
    { immediate: true }
  );

  const getGeolocation = () => {
    if (!$ab.isVariant("enable-geolocation", "test")) {
      return;
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          userLocation.value = {
            lat: position.coords.latitude,
            lon: position.coords.longitude,
          };
          $trackers.trackEvent({
            title: "Geolocation enabled",
            data: {
              lat: position.coords.latitude,
              lon: position.coords.longitude,
            },
          });
        },
        (error) => {
          $trackers.trackEvent({
            title: "Geolocation disabled/error",
          });
          console.error("Error getting geolocation: ", error);
        }
      , {
        enableHighAccuracy: true,
        timeout: 15000, // 15 seconds
        maximumAge: 300000, // 5 minutes
      });
    }
  }

  return {
    cities,
    loading,
    cityFilter,
    getGeolocation,
  };
}
