<template>
  <JoszakiAutocomplete
    v-model="professionGroupValue"
    v-bind="$attrs"
    icon="th-large"
    :items="filteredProfessionGroups"
    :placeholder="value?.name ?? '-'"
    @input="onInput"
    @focus="onFocus"
    @blur="onBlur"
    @select="onSelect"
  >
    <template #empty>
      {{ $t("conductor.common.noResults") }}
    </template>
  </JoszakiAutocomplete>
</template>

<script>
import { ref } from "@nuxtjs/composition-api";
import { debounce } from "~/helpers/debounce";
import { useProfessionGroup } from "~/components/_refactored/conductor/professionGroups/useProfessionGroup";

export default {
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const { getProfessionGroups } = useProfessionGroup();
    const filteredProfessionGroups = ref([]);
    const professionGroupFilter = ref("");
    const professionGroupValue = ref(props.value?.name ?? "-");
    const focused = ref(false);

    const onInput = debounce(async function (search) {
      const { items } = await getProfessionGroups({
        search,
        page: 1,
        pageSize: 15,
      });
      filteredProfessionGroups.value = items;
    }, 500);

    return {
      filteredProfessionGroups,
      professionGroupFilter,
      professionGroupValue,
      onInput,
      focused,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.professionGroupValue = newValue.name;
        }
      },
    },
  },
  methods: {
    onFocus() {
      this.focused = true;
      this.professionGroupValue = this.professionGroupFilter;
      this.onInput(this.professionGroupFilter);
      this.$emit("focus");
    },
    onBlur() {
      this.focused = false;
      this.professionGroupFilter = this.professionGroupValue;
      this.professionGroupValue = this.value?.name ?? "-";
      this.$emit("blur");
    },
    onSelect(group) {
      this.professionGroupFilter = "";
      if (group) {
        this.$emit("select", group);
      } else {
        this.onBlur();
      }
    },
  },
};
</script>
