/**
 * Abstract class for errors that should be reported to Sentry
 */
class SentryReportableError extends Error {
  reportToSentry() {
    throw new Error("Method not implemented.");
  }
}

export class GraphQLError extends SentryReportableError {
  constructor(error) {
    super(error.message);
    this.name = "GraphQLError";
    this.code = error.code ?? error.extensions?.code;
    this.backendStack = error.stack;
    this.path = error.path;
    this.cause = error;
  }

  reportToSentry(sentry) {
    sentry.withScope((scope) => {
      scope.setTransactionName(this.code);

      sentry.captureException(this);
    });
  }
}

export class GraphQLUserError extends SentryReportableError {
  constructor(userError) {
    super(userError.message);
    this.name = "GraphQLUserError";
    this.mutationName = userError.mutationName;
    this.data = userError.data;
    this.field = userError.field;
    this.cause = userError;
  }

  reportToSentry(sentry) {
    sentry.withScope((scope) => {
      scope.setTransactionName(this.mutationName);
      sentry.captureException(this);
    });
  }
}
