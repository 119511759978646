<template>
  <div class="joszaki-table max-h-full flex flex-col">
    <div class="flex-1 overflow-auto relative">
      <table
        class="max-h-full p-4 border-spacing-2 w-full relative border border-gray-300 text-sm"
        cellspacing="0"
      >
        <thead class="bg-primary sticky top-0">
          <slot name="thead" />
        </thead>
        <tbody
          class="[&>*:nth-child(even)]:bg-primary-lightest/30 [&>tr:hover]:bg-primary-lightest"
        >
          <slot name="tbody" />
        </tbody>
        <tfoot>
          <slot name="tfoot" />
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script>
/**
 * @deprecated Should be merged with JoszakiDataTable which should use an external DataTable library
 */
export default {
  props: {},
};
</script>

<style lang="postcss">
.joszaki-table th {
  @apply p-2 !text-white;
}

.joszaki-table tr > th:first-child {
  @apply !pl-2;
}

.joszaki-table tr > td {
  @apply align-middle p-2;
}

.joszaki-table thead > tr {
  @apply py-2;
}

.joszaki-table tfoot > tr {
  @apply py-2 bg-gray-300;
}
</style>
