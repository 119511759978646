import { omit } from "lodash";
import { USER_JWT_TOKEN_COOKIE_NAME } from "~/components/_refactored/auth/constants";
import { GraphQLError, GraphQLUserError } from "~/components/_refactored/error";

export default function (context, inject) {
  const { app, $joszaki, $logger, $sentry, i18n } = context;

  const statusCodeForErrorCode = {
    USER_ERROR: 400,
    BAD_USER_INPUT: 400,
    BAD_REQUEST: 400,
    GRAPHQL_VALIDATION_FAILED: 400,
    UNAUTHENTICATED: 401,
    FORBIDDEN: 403,
    ENTITY_NOT_FOUND: 404,
    INTERNAL_SERVER_ERROR: 500,
  };

  function reportResponseErrorsIfAny(err) {
    if (!err.response?.errors) {
      return;
    }
    err.response.errors.forEach((error) => {
      const wrappedError = new GraphQLError(error);

      wrappedError.reportToSentry($sentry);
    });
  }

  function getErrorMessage(error) {
    if (!error.message || error.message.startsWith("Error while fetching subquery from service")) {
      return null;
    }

    if (app.i18n.te(`error.messages.${error.message}`)) {
      return app.i18n.t(
        `error.messages.${error.message}`,
        error?.extensions?.data ?? {}
      );
    }

    return error.message;
  }

  function handleAndGetErrorMessage(
    err,
    {
      showErrorAlert,
      showErrorPage,
      showErrorToast,
      actionErrorMessage,
      skipErrorPageOnAuthError,
    }
  ) {
    // Currently the API returns errors inconsistently, later we should only use err.response.errors
    const errors = [
      ...(err?.response?.errors ?? []),
      ...(err?.graphQLErrors ?? []),
      ...(err?.userErrors ?? []),
      err,
    ];

    $sentry.addBreadcrumb({
      category: "graphql",
      data: errors,
      level: "error",
    });

    const error = errors[0];
    const errorCode = error.code ?? error.extensions?.code;

    const errorCodeMessage = app.i18n.te(`error.codes.${errorCode}`)
      ? app.i18n.t(`error.codes.${errorCode}`)
      : app.i18n.t("error.codes.UNKNOWN");
    const errorMessage = getErrorMessage(error);

    const messagePrefix = actionErrorMessage ? `${actionErrorMessage} ` : "";
    const messageSuffix = errorMessage ? `: ${errorMessage}` : "";
    const combinedErrorMessage = `${messagePrefix}${errorCodeMessage}${messageSuffix}`;

    if (showErrorToast) {
      $joszaki.toast({
        message: combinedErrorMessage,
        type: "error",
      });
    }

    if (showErrorAlert) {
      $joszaki.alert({
        title: errorCodeMessage,
        message: `${messagePrefix}${errorMessage ?? errorCodeMessage}`,
        type: "error",
      });
    }

    const errorPageVisible =
      showErrorPage ||
      (!skipErrorPageOnAuthError &&
        ["UNAUTHENTICATED", "FORBIDDEN"].includes(errorCode));

    if (errorPageVisible) {
      context.error({
        statusCode: statusCodeForErrorCode[errorCode] ?? 500,
        message: errorCodeMessage,
        detailedMessage: `${messagePrefix}${errorMessage ?? ""}`,
      });
    }
    return combinedErrorMessage;
  }

  async function mutate(
    mutation,
    variables = {},
    {
      showErrorAlert = false,
      showErrorToast = false,
      showErrorPage = false,
      showSuccessToast = false,
      showSuccessAlert = false,
      errorMessage = null,
      successMessage = null,
    } = {}
  ) {
    const mutationName = mutation.definitions?.[0]?.name?.value;
    const requestHeaders = {
      "Apollo-Require-Preflight": "true", // https://www.apollographql.com/docs/router/configuration/csrf/#configuring-csrf-prevention-in-the-router
    };

    const token = app.$cookie.get(USER_JWT_TOKEN_COOKIE_NAME);
    if (token) {
      requestHeaders.authorization = `Bearer ${token}`;
    }

    try {
      $logger.info("mutation called", {
        mutationName,
        variables: omit(variables, ["password", "newPassword"]),
      });

      const result = await context.$graphql.default.request(
        mutation,
        variables,
        requestHeaders
      );
      if (result[mutationName]?.userErrors) {
        throw result[mutationName];
      }
      if (showSuccessToast) {
        $joszaki.toast({
          message: successMessage ?? i18n.t("common.operationSuccess"),
          type: "success",
        });
      }
      if (showSuccessAlert) {
        $joszaki.alert({
          message: successMessage ?? i18n.t("common.operationSuccess"),
          type: "success",
        });
      }
      return Promise.resolve(result[mutationName]);
    } catch (err) {
      reportResponseErrorsIfAny(err);

      if (err.userErrors) {
        err.userErrors.forEach((error) => {
          const wrappedError = new GraphQLUserError({ ...error, mutationName });

          wrappedError.reportToSentry(context.$sentry);
        });
      }

      const message = handleAndGetErrorMessage(err, {
        showErrorAlert,
        showErrorPage,
        showErrorToast,
        actionErrorMessage: errorMessage,
      });

      return Promise.reject(message);
    }
  }

  async function query(
    query,
    variables = {},
    {
      showErrorAlert,
      showErrorPage,
      showErrorToast,
      errorMessage,
      skipErrorPageOnAuthError = false,
    } = {}
  ) {
    try {
      const requestHeaders = {};

      const token = app.$cookie.get(USER_JWT_TOKEN_COOKIE_NAME);
      if (token) {
        requestHeaders.authorization = `Bearer ${token}`;
      }

      const result = await context.$graphql.default.request(
        query,
        variables,
        requestHeaders
      );
      return Promise.resolve(result);
    } catch (err) {
      reportResponseErrorsIfAny(err);

      const message = handleAndGetErrorMessage(err, {
        showErrorAlert,
        showErrorPage,
        showErrorToast,
        actionErrorMessage: errorMessage,
        skipErrorPageOnAuthError,
      });

      return Promise.reject(message);
    }
  }

  inject("mutate", mutate);
  inject("query", query);
}
