var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._t("label",function(){return [(_vm.label)?_c('p',{staticClass:"text-base font-bold",class:{
        'text-error': _vm.failed,
      }},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e()]},{"failed":_vm.failed}),_c('div',{staticClass:"flex flex-wrap w-full relative"},[_c('JoszakiInputWrapper',[_c('div',{staticClass:"text-gray-300 placeholder-gray-300 h-full cursor-pointer flex items-center gap-1 flex-initial",on:{"click":function($event){$event.stopPropagation();return _vm.downClicked.apply(null, arguments)}}},[(_vm.value.countryCode)?[_c('div',{staticClass:"flex-0"},[_c('IconComponent',{staticClass:"w-6",attrs:{"pack":"flags","icon":_vm.value.countryCode.iso.trim()}})],1),_c('span',{staticClass:"text-gray-700"},[_vm._v(" +"+_vm._s(_vm.value.countryCode.code)+" ")])]:[_c('span'),_c('span')],(_vm.phoneCountryCodes.length > 1)?_c('div',{staticClass:"flex-0"},[_c('IconComponent',{staticClass:"text-gray-700",attrs:{"icon":"angle-down"}})],1):_vm._e()],2),_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.state.internalValue),expression:"state.internalValue"}],ref:"input",staticClass:"flex-1 focus:outline-none min-w-0",attrs:{"type":"tel","placeholder":_vm.placeholder},domProps:{"value":(_vm.state.internalValue)},on:{"keypress":_vm.numericOnly,"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.state, "internalValue", $event.target.value)},_vm.onInput],"keyup":function($event){return _vm.$emit('keyup', $event)}}},'input',_vm.$attrs,false)),_c('div',{staticClass:"flex-0 flex items-center cursor-pointer text-gray-400"},[_c('IconComponent',{class:{
            'text-success': !_vm.invalid,
            'text-error': _vm.invalid,
          },attrs:{"icon":_vm.invalid ? 'times-circle' : 'check-circle'}})],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.state.showDropdown),expression:"state.showDropdown"}],staticClass:"absolute bg-white rounded-md z-10 border border-gray-300 shadow-md max-w-[6rem]",class:{
        'top-full left-0 right-0': _vm.dropDownPosition === 'bottom',
        'bottom-full left-0 right-0': _vm.dropDownPosition === 'top',
      }},[_c('div',{ref:"container",staticClass:"max-h-52 overflow-y-auto"},_vm._l((_vm.phoneCountryCodes),function(countryCode){return _c('div',{key:countryCode.id,staticClass:"py-2 pl-2 font-semibold flex gap-2 items-center px-1 hover:bg-gray-100 cursor-pointer",on:{"click":function($event){return _vm.selectCountryCode(countryCode)}}},[_c('IconComponent',{staticClass:"w-6",attrs:{"pack":"flags","icon":countryCode.iso.trim()}}),_vm._v(" +"+_vm._s(countryCode.code)+" ")],1)}),0)])],1),(_vm.showErrorMsg)?_c('p',{staticClass:"text-sm text-error",class:{
      invisible: !_vm.failed,
    }},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }