<template>
  <div class="flex flex-row gap-2 items-center md:items-stretch flex-wrap">
    <div
      v-for="(item, index) in value"
      :key="`uploaded-${index}`"
      class="flex flex-col gap-2 items-center"
    >
      <img
        v-if="item.previewImage"
        class="w-48 max-h-48 object-contain"
        v-lazy-load :data-src="item.previewImage"
      />
      <IconComponent v-else icon="file-upload" class="w-8" />
      <span class="max-w-[200px] truncate">{{ item.file.name }}</span>
      <JoszakiButton
        icon-left="trash"
        type="error"
        size="sm"
        @click="removeUploadedFile(item.fileId)"
      >
        {{ $t("common.delete") }}
      </JoszakiButton>
    </div>
    <div
      v-for="(item, index) in filesToUpload"
      :key="`in-progress-${index}`"
      class="flex flex-col gap-2 items-center"
    >
      <img
        v-if="item.previewImage"
        class="w-48 max-h-48 object-contain"
        v-lazy-load :data-src="item.previewImage"
      />
      <IconComponent v-else icon="file-upload" class="w-8" />
      <span class="max-w-[200px] truncate">{{ item.file.name }}</span>
      <IconComponent icon="spinner" class="animate-spin w-8" />
    </div>
    <div
      v-for="(item, index) in filesFailedToUpload"
      :key="`failed-${index}`"
      class="flex flex-col gap-2 items-center"
    >
      <img
        v-if="item.previewImage"
        class="w-48 max-h-48 object-contain"
        v-lazy-load :data-src="item.previewImage"
      />
      <IconComponent v-else icon="file-upload" class="w-8" />
      <span class="max-w-[200px] truncate">{{ item.file.name }}</span>
      <JoszakiButton icon-left="redo-alt" size="sm" @click="retryUpload(item)">
        {{ $t("common.retry") }}
      </JoszakiButton>
      <JoszakiButton
        icon-left="trash"
        type="error"
        size="sm"
        @click="removeFailedFile(item.uuid)"
      >
        {{ $t("common.delete") }}
      </JoszakiButton>
      <span class="text-error">{{ item.error ?? $t("uploader.error") }}</span>
    </div>
    <div
      v-if="allowMultiple || !hasSelectedFile"
      class="bg-gray-300/20 shadow-md border-2 border-dashed border-gray-500 rounded-md cursor-pointer hover:bg-primary/5 hover:border-primary min-w-[200px] flex-1"
      @drop.prevent="onDrop"
      @dragenter.prevent="onDragEnter"
      @dragover.prevent
      @dragleave.prevent="onDragLeave"
    >
      <div class="!p-6 relative h-full">
        <p class="my-2">
          {{ message ?? $t("uploader.selectFile") }}
        </p>
        <JoszakiButton class="relative">
          <input
            class="absolute opacity-0 top-0 right-0 left-0 bottom-0 cursor-pointer"
            type="file"
            :multiple="allowMultiple"
            :accept="accept"
            @change="onChange"
          />
          {{ label ?? $t("uploader.openFile") }}
        </JoszakiButton>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, useContext } from "@nuxtjs/composition-api";
import { v4 } from "uuid";
import omit from "lodash/omit";
import REQUEST_SIGNED_UPLOAD_URL from "~/components/_refactored/common/upload/requestSignedUploadUrl.graphql";

export default {
  props: {
    value: {
      type: [Array],
      required: false,
      default: () => [],
    },
    label: {
      type: String,
      default: null,
    },
    accept: {
      type: String,
      default: "*",
    },
    message: {
      type: String,
      default: null,
    },
    allowMultiple: {
      type: Boolean,
      default: false,
    },
    fileCategory: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const dragging = ref(false);
    const filesToUpload = ref([]);
    const filesFailedToUpload = ref([]);
    const { $joszaki, i18n, $query } = useContext();

    const hasSelectedFile = computed(() => {
      return (
        filesToUpload.value.length > 0 ||
        filesFailedToUpload.value.length > 0 ||
        props.value.length > 0
      );
    });

    const onDragEnter = (event) => {
      dragging.value = true;
      event.dataTransfer.dropEffect = "copy";
      event.currentTarget.classList.add("bg-primary/5", "border-primary");
    };

    const onDragLeave = (event) => {
      dragging.value = false;
      event.currentTarget.classList.remove("bg-primary/5", "border-primary");
    };

    const onDrop = (event) => {
      const files = event.dataTransfer.files;
      upload(files);
    };

    const onChange = (event) => {
      const files = event.target.files;
      upload(files);
    };

    const validate = (files) => {
      if (props.value.length > 0 && !props.allowMultiple) {
        $joszaki.toast({
          message: i18n.t("uploader.singleFileOnly"),
          type: "error",
        });
        return false;
      }

      const hasLargeFile = Array.from(files)
        .map((file) => file.size)
        .some((size) => size > process.env.MAX_UPLOAD_SIZE_IN_MB * 1000 * 1000);

      if (hasLargeFile) {
        $joszaki.toast({
          message: i18n.t("uploader.maxFileSize", {
            maxSize: process.env.MAX_UPLOAD_SIZE_IN_MB,
          }),
          type: "error",
        });

        return false;
      }

      if (props.accept === "*") {
        return true;
      }

      const acceptedTypes = props.accept.split(",").map((type) => type.trim());
      const hasInvalidType = Array.from(files).some((file) => {
        return (
          !acceptedTypes.includes(file.type) &&
          !acceptedTypes.some(
            (type) =>
              type.endsWith("/*") && file.type.startsWith(type.slice(0, -1))
          )
        );
      });

      if (hasInvalidType) {
        $joszaki.toast({
          message: i18n.t("uploader.invalidFileType"),
          type: "error",
        });
        return false;
      }

      return true;
    };

    const upload = (files) => {
      const isValid = validate(files);

      if (!isValid) {
        return;
      }

      for (const file of files) {
        const fileItem = {
          file,
          uuid: v4(),
        };

        if (props.accept.startsWith("image/")) {
          fileItem.previewImage = URL.createObjectURL(file);
        }
        filesToUpload.value.push(fileItem);
        uploadFile(fileItem);
      }
    };

    const getSignedUrl = async (fileCategory) => {
      const { requestSignedUploadUrl } = await $query(
        REQUEST_SIGNED_UPLOAD_URL,
        {
          fileCategory,
        }
      );

      return requestSignedUploadUrl;
    };

    const uploadFile = async (fileItem) => {
      try {
        if (!fileItem.url) {
          const signedUrlResponse = await getSignedUrl(props.fileCategory);

          fileItem.url = signedUrlResponse.url;
          fileItem.valueForContentTypeHeader =
            signedUrlResponse.valueForContentTypeHeader;
          fileItem.fileId = signedUrlResponse.fileId;
        }

        await fetch(fileItem.url, {
          method: "PUT",
          body: fileItem.file,
          headers: {
            "Content-Type": fileItem.valueForContentTypeHeader,
          },
        });

        emit("input", [...props.value, omit(fileItem, ["uuid", "error"])]);
      } catch (error) {
        console.error("Error uploading file", error);
        filesFailedToUpload.value.push({
          ...fileItem,
          error,
        });
      } finally {
        filesToUpload.value = filesToUpload.value.filter(
          (f) => f.uuid !== fileItem.uuid
        );
      }
    };

    const removeFailedFile = (uuid) => {
      filesFailedToUpload.value = filesFailedToUpload.value.filter(
        (f) => f.uuid !== uuid
      );
    };

    const removeUploadedFile = (fileId) => {
      emit(
        "input",
        props.value.filter((f) => f.fileId !== fileId)
      );
    };

    const retryUpload = (fileItem) => {
      filesFailedToUpload.value = filesFailedToUpload.value.filter(
        (f) => f.uuid !== fileItem.uuid
      );
      uploadFile(fileItem);
    };

    return {
      dragging,
      filesToUpload,
      filesFailedToUpload,
      hasSelectedFile,
      onDragEnter,
      onDragLeave,
      onDrop,
      onChange,
      removeFailedFile,
      removeUploadedFile,
      retryUpload,
    };
  },
};
</script>
