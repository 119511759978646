var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"JoszakiCard max-lg:p-3 lg:p-4 rounded-md shadow-md border border-gray-300",class:{
    'hover:shadow-xl': _vm.hoverable,
    'bg-white': 'default' === _vm.type,
    'bg-gray-50': 'gray' === _vm.type,
    'bg-info text-white': 'info' === _vm.type,
    'bg-primary text-white': 'primary' === _vm.type,
    'bg-success text-white': 'success' === _vm.type,
    'bg-warning': 'warning' === _vm.type,
    'bg-danger text-white': 'danger' === _vm.type,
    'bg-error': 'error' === _vm.type,
    'bg-rating': 'rating' === _vm.type,
  }},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }