<template>
  <JoszakiModal
    :title="$t('conductor.dataTable.addFilter')"
    :open="isOpen"
    size="md"
    @cancel="close"
    @close="close"
    @confirm="save"
  >
    <template #body>
      <div v-if="filterValue" class="flex flex-col gap-2 min-h-[150px]">
        <JoszakiDropdown
          :items="operatorListItems"
          :value="filterValue.operator"
          @input="filterValue.operator = $event"
        />

        <component
          :is="autocompleteComponents[filterOption.fieldType]"
          v-if="isAutocomplete"
          :value="filterValue.value"
          :is-multi-select="isMultiSelect"
          :selected-items="filterValue.items"
          @select="selectItem"
          @remove="removeItem"
        />
        <JoszakiAutocomplete
          v-else-if="filterOption.fieldType === 'select'"
          :items="filterOption.items"
          :is-multi-select="isMultiSelect"
          :value="filterValue.value?.name ?? '-'"
          :selected-items="filterValue.items"
          @select="selectItem"
          @remove="removeItem"
        />

        <JoszakiCheckbox
          v-else-if="filterOption.fieldType === 'checkbox'"
          v-model="filterValue.value"
          :label="filterOption.label"
        />
        <JoszakiInput
          v-else-if="isSimpleValue"
          v-model="filterValue.value"
          :type="filterOption.fieldType"
        />
        <JoszakiInput
          v-if="filterValue.operator === OPERATORS.BETWEEN"
          v-model="filterValue.min"
          :type="filterOption.fieldType"
        />
        <JoszakiInput
          v-if="filterValue.operator === OPERATORS.BETWEEN"
          v-model="filterValue.max"
          :type="filterOption.fieldType"
        />
      </div>
    </template>
  </JoszakiModal>
</template>

<script>
import { useContext } from "@nuxtjs/composition-api";
import { computed, ref } from "vue";
import { useDataTableFilterOperators } from "~/components/_refactored/conductor/data-table/useDataTableFilterOperators";
import AutocompleteCity from "~/components/_refactored/autocomplete/AutocompleteCity.vue";
import AutocompleteMiniMarketTeamAdmin from "~/components/_refactored/autocomplete/AutocompleteMiniMarketTeamAdmin.vue";
import AutocompletePersonAdmin from "~/components/_refactored/autocomplete/AutocompletePersonAdmin.vue";
import AutocompleteProfession from "~/components/_refactored/autocomplete/AutocompleteProfession.vue";
import AutocompleteProfessionTask from "~/components/_refactored/autocomplete/AutocompleteProfessionTask.vue";
import AutocompleteProfessionGroupAdmin from "~/components/_refactored/autocomplete/AutocompleteProfessionGroupAdmin.vue";
import AutocompleteZone from "~/components/_refactored/autocomplete/AutocompleteZone.vue";
import AutocompleteSubcounty from "~/components/_refactored/autocomplete/AutocompleteSubcounty.vue";

export default {
  components: {
    AutocompleteProfession,
    AutocompleteCity,
    AutocompleteMiniMarketTeamAdmin,
    AutocompleteProfessionGroupAdmin,
  },
  props: {
    filterOptions: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { i18n } = useContext();
    const isOpen = ref(false);
    const filterValue = ref(null);
    const index = ref(null);
    const OPERATORS = useDataTableFilterOperators();

    const filterOption = computed(() => {
      if (!filterValue.value) {
        return null;
      }

      return props.filterOptions.find(
        (option) => option.id === filterValue.value.filterId
      );
    });

    const autocompleteComponents = {
      city: AutocompleteCity,
      miniMarketTeam: AutocompleteMiniMarketTeamAdmin,
      person: AutocompletePersonAdmin,
      profession: AutocompleteProfession,
      professionGroup: AutocompleteProfessionGroupAdmin,
      professionTask: AutocompleteProfessionTask,
      zone: AutocompleteZone,
      subcounty: AutocompleteSubcounty,
    };
    const isAutocomplete = computed(() => {
      return Object.keys(autocompleteComponents).includes(
        filterOption.value?.fieldType
      );
    });

    const isMultiSelect = computed(() => {
      return (
        filterValue.value?.operator === OPERATORS.IN ||
        filterValue.value?.operator === OPERATORS.NOT_IN
      );
    });

    const isSimpleValue = computed(() => {
      return [OPERATORS.EQUALS, OPERATORS.NOT_EQUALS, OPERATORS.LIKE].includes(
        filterValue.value.operator
      );
    });

    const operatorListItems = computed(() => {
      return (filterOption.value?.operators ?? [OPERATORS.EQUALS]).map(
        (operator) => ({
          value: operator,
          label: i18n.t(`conductor.dataTable.operatorNames.${operator}`, {
            field: filterOption.value?.label,
          }),
        })
      );
    });

    const selectItem = (item) => {
      filterValue.value.items.push(item);
      filterValue.value.value = item;
    };

    const removeItem = (item) => {
      filterValue.value.items = filterValue.value.items.filter(
        (i) => i.id !== item.id
      );
    };

    const open = (value, i) => {
      filterValue.value = {
        ...value,
      };
      index.value = i;
      isOpen.value = true;
    };

    const close = () => {
      isOpen.value = false;
      index.value = null;
      filterValue.value = null;
    };

    const save = () => {
      emit("save", filterValue.value, index.value);
      close();
    };

    return {
      OPERATORS,
      isOpen,
      open,
      close,
      save,
      operatorListItems,
      filterOption,
      isMultiSelect,
      isAutocomplete,
      isSimpleValue,
      autocompleteComponents,
      filterValue,
      selectItem,
      removeItem,
    };
  },
};
</script>
