import { useContext } from "@nuxtjs/composition-api";

export function useRatingActions() {
  const { $mutate, $query } = useContext();

  async function saveRating(submitData) {
    try {
      if (submitData.id !== null) {
        const UPDATE_RATING = await import("./updateRating.graphql");
        return await $mutate(UPDATE_RATING, submitData);
      } else {
        throw new Error("Method not implemented.");
      }
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  }

  async function deleteRatingById(id) {
    try {
      const DELETE_RATING = await import("./deleteRatingById.graphql");
      await $mutate(DELETE_RATING, { id });
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  }

  async function restoreRatingById(id) {
    try {
      const RESTORE_RATING = await import("./restoreScore.graphql");
      await $mutate(RESTORE_RATING, { scoreId: id });
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  }

  async function getRatingById(id) {
    try {
      const GET_RATING = await import("./getRatingById.graphql");
      const { ratingById } = await $query(GET_RATING, { id });
      return ratingById;
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  }

  async function getRatings(params) {
    try {
      const GET_RATINGS = await import("./ratingsDataTable.graphql");
      const { ratingsDataTable } = await $query(GET_RATINGS, params);
      return ratingsDataTable;
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  }

  return {
    saveRating,
    deleteRatingById,
    restoreRatingById,
    getRatingById,
    getRatings,
  };
}
