import { defineStore } from "pinia";
import SETTINGS_QUERY from "./settings.graphql";

export const useSettingsStore = defineStore("settings", {
  state: () => ({
    settings: {},
  }),
  getters: {
    isZoneOnly() {
      return this.settings.zone_only === "true";
    },
    isFavoritesDisabled() {
      return this.settings.disable_favorites === "true";
    },
    countryCenterCoords() {
      const coords = this.settings.country_center_coords;
      return coords ? JSON.parse(coords) : [0, 0];
    },
    supportPhoneVisible() {
      return this.settings.support_phone_visible === "true";
    },
    getSettings() {
      return this.settings;
    },
  },
  actions: {
    async loadSettings() {
      try {
        const result = await this.$nuxt.$query(SETTINGS_QUERY);
        console.debug("settings result", result);
        this.settings = result.settings.reduce((accumulator, currentValue) => {
          accumulator[currentValue.name] = currentValue.value;
          return accumulator;
        }, {});
      } catch (err) {
        console.error(err);
        this.$nuxt.$logger.error("Failed to load settings", err);
      }
    },
  },
});
