import { defineStore } from "pinia";
import { useCookieManager } from "./composables/useCookieManager";
import { useCheckMismatch } from "./composables/useCheckMismatch";
import { useUserStore } from "~/stores/user";

/**
 * Store that can work both client and server side.
 */
export const useAuthStore = defineStore(
  "useAuthStore",
  {
    state: () => ({
      userToken: null,
      adminToken: null,
    }),
    getters: {
      isAuthenticated: (state) => !!state.userToken,
    },
    actions: {
      setToken(token) {
        this.userToken = token;

        useCookieManager(this.$nuxt).setTokenCookie(token, "user");
      },
      setAdminToken(token) {
        this.adminToken = token;
        useCookieManager(this.$nuxt).setTokenCookie(token, "admin");
      },
      logout() {
        useCookieManager(this.$nuxt).clearTokenCookieForAll();
        this.userToken = null;
        this.adminToken = null;
        useUserStore().$reset();

        // TODO find a better place for this
        this.$nuxt.$sentry.setUser(null);
      },
      async loginWithToken(token, skipAuthErrorPages = false) {
        this.setToken(token);
        const userStore = useUserStore();

        await userStore.queryMe(skipAuthErrorPages);

        try {
          this.$nuxt.$sentry.setUser({
            id: userStore.me.id,
            email: userStore.me.email,
          });

          this.$nuxt.$trackers.identifyUser({
            id: userStore.me.id,
            name: userStore.me.name,
            email: userStore.me.email,
          });
        } catch (error) {
          console.error("Error when settings user for third party apps", error);
        }
        // TODO find a better place for this

        useCheckMismatch(this.$nuxt)(userStore.me.id, this.userToken);

        if (userStore.me.isAdmin) {
          this.setAdminToken(this.userToken);
        }

        useCookieManager(this.$nuxt).clearCampaignsCookie();
      },
    },
  },
  {}
);
